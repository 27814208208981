import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import * as React from 'react'
import theme from '../theme'
import Header from '../components/header'
import Footer from '../components/footer'
import FabNav from '../components/fabnav'

const HeadShared = ({children}: {children: React.ReactNode}) => {
      return (
        <ThemeProvider theme={theme} key="themeProvider">
            <CssBaseline/>
            <Header />
            {children}
            <FabNav />
            <Footer />
        </ThemeProvider>
      )
  }
  
  export default HeadShared