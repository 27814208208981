import { Box, Fab, Zoom } from '@mui/material';
import React, { useEffect } from 'react';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
const FabNav = () => {
    const [isOnTop, setIsOnTop] = React.useState<boolean>(true)
    const style = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        zIndex: 9999
    };
    const handleScroll = () => {
        if (window.scrollY > 200) {
            setIsOnTop(false)
        } else {
            setIsOnTop(true)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])
    const handleToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    return <Box sx={style}>
        <Zoom
          in={!isOnTop}
          timeout={200}
          style={{
            transitionDelay: `${!isOnTop ? 200 : 0}ms`,
          }}
          unmountOnExit
        >
            <Fab color="primary" onClick={handleToTop} aria-label='To Top'>
                <UpIcon />
            </Fab>
        </Zoom>
    </Box>
}
export default FabNav;