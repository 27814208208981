import React from 'react'
import { Box, Card, CardContent, Link } from "@mui/material"
import { Foot, bluredBackgroundAppbarStyle, noLinkStyle } from "../utils/styles"

const Footer = () => {
    return (
    <Foot>
        <Card variant="outlined" sx={{ width: '100%', ...bluredBackgroundAppbarStyle }}>
            <CardContent style={{ paddingTop: '24px' }}>
                <Box display="flex" flexDirection="column">
                    <Box textAlign="center" fontWeight="bold">SoundReload {new Date().getFullYear()}</Box>
                    <Box textAlign="center">AllTheToys Project</Box>
                </Box>
            </CardContent>
        </Card>
    </Foot>
    )
}

export default Footer;