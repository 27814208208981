import React from 'react';
import { HeaderContainer, HeaderLeft, HeaderRight, blurCardBackgroundStyle, bluredBackgroundAppbarStyle, noLinkStyle } from '../utils/styles';
import { AppBar, Box, Button, Container, Toolbar, Typography, Link as MdLink, IconButton, Menu, MenuItem, Icon } from '@mui/material';
import { Link } from 'gatsby';
import MenuIcon from '@mui/icons-material/Menu';
const Header = () => {
    const links = {
        首页: '/',
        小道具: '/magic-toys',
        测量表工具: '/tools',
    }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <AppBar position="static" sx={bluredBackgroundAppbarStyle}>
            <Toolbar>
                {/* <Box sx={{ width: '256px', height: '100%' }}>
                    <Box sx={{ display: { xs: 'inline-block', md: 'none' } }}>
                        <Button
                            aria-label='menu'
                            aria-controls={open ? 'positioned-menu' : 'positioned-button'}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : 'false'}
                            onClick={handleClick}
                            sx={{ alignItems: 'baseline' }}
                        >
                            <Icon>
                                <MenuIcon/>
                            </Icon>
                            <div>菜单</div>
                        </Button>
                        <Menu
                            aria-labelledby="positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {Object.keys(links).map((link, index) => {
                                return <MenuItem to={links[link]} onClick={handleClose} key={index} component={Link} sx={{background: 'rgba(0,0,0,0.5)' }}>{link}</MenuItem>
                            })}
                        </Menu>
                    </Box>
                </Box> */}
                <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box>
                        {/* {Object.keys(links).map((link, index) => {
                            return <Button sx={{display:'flex', ...noLinkStyle}} to={links[link]} component={Link}>{link}</Button>
                        })} */}
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Yoko Web
                        </Typography>
                    </Box>
                </Container>
                {/* <Box sx={{ width: {
                    xs: '256px', md: '128px'
                } }}>
                    <Button sx={{display:'flex', color: '#ff5f00 !important', ...noLinkStyle}} href='https://huyaoshouzuo.taobao.com' target="_blank" component={MdLink}>去往淘宝</Button>
                </Box> */}
            </Toolbar>
        </AppBar>
    );
}

export default Header;